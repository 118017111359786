.login-root {
  height: 100%;
  background-color: white;
  overflow: hidden;

  .ant-card {
    line-height : none !important
  }

  .image-field{
    margin: 0;
    padding: 0;
    background-image: "../../Assets/images/Background Image.png";
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .ant-card-bordered {
    border: none !important;
  }

  .login-background{
    overflow:hidden;
    position:relative;
    .image-field{
      // position:absolute;
      // top:0;
      // left:50%;
      // margin-left:-960px;
    }
  }

  .mob-tab-header{
    display: none;
  }
  .mob-tab-header-small{
    display: flex;
    width: 100%;
    height: 45px;
    background-color: #082C7C
  }

  .card-container{
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .login-image {
    background-image: url('../../Assets/images/Background\ Image.png'); /* Update this path */
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    filter: brightness(60%);
    // opacity:0.8
  }
  .leftCardSection{
    background-color: #FFF29C;
    padding: 2rem 3rem;
    min-height: 30rem;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
  }
  .kolHeader{
    text-align: center;
    font-weight: bold;
    // font-size: 20px;
    margin-bottom: 3rem;
    font-size: 24px;
    font-weight: 500;
    // line-height: 24px;
    letter-spacing: 0.25px;
  }
  [class~='ant-carousel'] {
    [class~='slick-slider'] {
      li button {
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background-color: rgb(180, 179, 179);
          }
       li.slick-active button {
            width: 16px;
            height: 8px;
            border-radius: 40%;
            background-color: grey;
          }
    }
}
  .schedule{
    text-align: center;
    // font-size: 17px;
    margin-bottom: 2rem;
    // font-family: 'Roboto';
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.25px;
    color: #000000;
    // text-align: center;
  }
  .header-cnt-small{
    color: white;
    font-family: 'Inter-Regular';
    font-weight: 500;
    padding-left:  20px;
    padding-top: 9px;
    font-size: 18px;
   }

  .header-cnt{
     display: none;
  }


  .ant-input {
    min-height: 0;
  }

  .left-content-small {
    display: none;
  }
  .left-content{
    display: flex;
    width: 70%;
  }
  .right-content {
    display: flex;
    justify-content: center;
    // margin-inline: 38%;
    width: 30%;
    margin-top: 35px;
  }
  .right-content-small {
    display: flex;
    justify-content: center;
    width: 100%;
    // margin-inline: 38%;
    // width: 30%;
    // margin-top: 35px;
  }
  .right-btn {
    margin-top: 10px !important;
    margin-right: 100px !important;
    // margin-left: -5px !important;
    padding-left: 0px;
    float: left !important;
  }
  .header-small{
    // display: none;
    margin-top: 20px;
    text-align: initial;
    font-size: 25px;
    // font-weight: lighter !important;
    font-family: Inter-Regular;
    text-align: center;
    font-weight: 600;
  }
  .header{
    text-align: initial;
    font-size: 25px;
    // font-weight: lighter !important;
    font-family: Inter-Regular;
    text-align: center;
    font-weight: 600;
    // color: #00287a;
  }
  .microsoft-login-button{
    border-radius: 10px !important;
    // padding: 0px 6rem !important;
    display: flex !important;
    justify-content: center;
    height: 40px !important;
    text-wrap: nowrap;
    // font-family: 'Roboto';
    font-size: 14px !important;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
    // text-align: left;
  }
  .microsoft-login-button-white{
    border-radius: 10px !important;
    // padding: 0px 6rem !important;
    display: flex !important;
    justify-content: center;
    height: 45px !important;
    text-wrap: nowrap;
    background: white !important;
    color: black !important;
    font-size: 14px !important;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.25px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 1px 1px !important;
  }
  .user-login-button{
    width:98%;
    margin: 5px;
    height: 40px !important;
    cursor: pointer;
    // font-size: 19px;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
  }
  .ant-divider-inner-text{
    color: #969696;
    font-weight: 400;
  }
  .login-button{
    background-color: black;
    color: white;
    border-radius: 10px;
    height: 45px;
    cursor: pointer;
    // font-size: 19px;\
    font-size: 14px !important;
    font-weight: 600;
    width:100%
  }
  .login-carousal{
    background: #FFF29C;
    display: flex;
    margin: 0;
    height: 100%;
    line-height: 100%;
  }

  .left-content {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: initial;
    height: 100vh;

    .bg-image {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
  @media (min-width : 1339px )
  {
    // .mob-tab-header{
    //   display: none;
    // }
    // .header-cnt{
    //  display: none;
    // }
  }
  
  //tablet
  @media(min-width : 600px) and (max-width : 1000px) {
    font-size: 14px !important;

    .right-content {
      display: flex;
      justify-content: center;
      margin-inline: 38%;
      width: 30%;
      margin-top: 35px;
    }
    .subHeader{
      // white-space: nowrap;
      // padding-right: 180px;
    }
    .left-content {
      display: none;
    }
    .mob-tab-header{
      width: 100%;
      height: 45px;
      background-color: #082C7C
   }
   .formCenter {
    padding: 10px 20px !important;
   }
  }

  //laptop small view 
  @media(min-width : 1000px) and (max-width : 1350px) {
    .left-content {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: initial;
      // height: 100vh;
      // width: 80%;

      .image-tab {
        width: 100%;
        object-fit: cover;
        object-position: 32% 0%;
      }
    }
    .mob-tab-header{
     display: none;
   }
}

  //mobile
  @media(max-width : 600px) {

    .mob-tab-header{
      width: 100%;
      height: 45px;
      background-color: #082C7C
   }
   .formCenter {
    padding: 10px 20px !important;
   }
  }

  button.ant-btn.ant-btn-link.right-btn {
    float: right;
    margin-top: -26px;
    margin-right: 10px;
  }



  .subHeader {
    font-size: 16px;
    font-family: Inter-Regular;
    margin-bottom: 1rem;
    letter-spacing: 0px;
    color: #767272;
    opacity: 1;
    text-align: center;
    margin-bottom: 3rem;

  }

  .login-username:hover{
    color: #0062ff;
    text-decoration: underline;
  }

  .ant-row.footer-content {
    bottom: 5px;
    position: fixed;
    width: 100%;
    left: 70%;
  }

  label.field-label {
    font-size: 12px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.6;
    margin-bottom: 5px;
  }

  .form-builder .submit-btn {
    padding: 0 25px;
    border-radius: 8px;
    font-size: 12px;
    width: 7rem;
    height: 29px;
  }

  // .header {
  //   text-align: initial;
  //   font-size: 30px;
  //   // font-weight: lighter !important;
  //   font-family: Inter-Regular;
  //   color: #00287a;
  // }

  .ant-divider.ant-divider-horizontal.lower-div {
    margin-top: 0px;
    border-color: #C9C9C9;
    margin-bottom: auto;
  }

  button.ant-btn.ant-btn-link.left-pane {
    font-size: 10px;
    color: #0a6ed1;
    padding-left: 30px;

  }

  button.ant-btn.ant-btn-link.right-pane {
    font-size: 10px;
    color: #0a6ed1;
  }

  .ant-divider.ant-divider-horizontal.upper-div {
    margin-bottom: 3px;
    margin-top: auto;
    border-color: #C9C9C9;
  }

  .top-left {
    position: absolute;
    font-family: Inter-Regular;
    top: 5%;
    left: 5%;
    color: white;
    font-size: 20px;
    font-weight: lighter;
  }

  button#sign-in {
    background: #0a6ed1;
    text-align: center;
    background: #ffe539 0% 0% no-repeat padding-box !important;
    border: 1px solid #fad906;
    border-radius: 6px;
    opacity: 1;
    // color: #00287a;
    color: #000000;
    font-weight: 700;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;

  }
  button#sign-in:hover {
    text-align: center;
    background: #ffe539 0% 0% no-repeat padding-box !important; 
    border: 2px solid #fad906;
    border-radius: 6px;
    opacity: 1;
    // color: #00287a;
    color: #000000;
    font-weight: 700;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 4px 2.25em 4em;
    transform: scale(1.07);

  }


  .ant-space-item {
    color: #00000080;
  }

  .ant-space.ant-space-horizontal.ant-space-align-center {
    display: inline-flex;
    margin-left: 0px;
    margin-top: 3px;

  }

  .ant-space.ant-space-horizontal.ant-space-align-center.copyright-part {
    margin-top: 6px;
    margin-left: 0px;
    font-size: 10px;
    padding: 4px 15px 0 30px;
  }

  // .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  // .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  //   background: #f0f0f0;
  // }

  .ant-form-item-explain-error {
    color: #ff4d4f;
    padding-top: 6px;
 }
  button.ant-btn.ant-btn-link.copyright-btn {
    padding: 0px;
    font-size: 11px;
    color: #0a6ed1;
  }

  .ant-form-item-explain-error {
    text-align: start;
  }

  .ant-col {
    text-align: initial;
    padding: 0px 12px 0 0 !important;
  }

  .ant-row.login-content {
    // height: 87vh;
    // display: flex;
    justify-content: center;
    align-items: center;
    line-height:normal;
  }

  // .ant-input-affix-wrapper {
  //   background: #f0f0f0;
  // }

  .formCenter {
    padding: 10px 20px !important;
    min-height: 65vh;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
   }
   .version{
    bottom: 0;
    position: absolute;
    margin: 0;
    color:#707070;
    margin-bottom: 5px;
   }

  .forgetPassword {
    text-align: flex-end;
  }

  // button#forgot-password {

  // }

  button#forgot-password {
    // padding-left: 47px;
    right: 35px;
  }

  .buttonGroup {
    display: flex;
    justify-content: space-between;
    width: 80%;
  }

  .btn {
    padding: 0 5%;
  }

  .bottomTab {
    width: 100%;
    height: 3rem;
    border: 1px solid gray;
    bottom: 0;
    position: absolute;
  }

  .formField {
    margin-bottom: 40px;
  }

  .formFieldLabel {
    color: white;
  }

  .formFieldInput {
    color: white;
    border-bottom: 1px solid #fafbfc;
  }


  .ant-image {
    display: block;
    margin-bottom: 20px;
  }

  .img {
    height: 100vh;
    width: 100%;
  }


  // .item {
  //   margin-top: 150px;
  //   margin-right: -50px;
  //   margin-left: 20px;
  // }

  .item input {
    width: 350px;

  }

  .item label {
    margin-top: 10px;
  }

  .h1 {
    margin-top: 175px;
    margin-bottom: 0.5em;
    color: #3F5C9A;
    font-weight: 500;
    padding-right: 150px;
  }

  .h2 {
    margin-top: 35px;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    padding-right: 80px;
  }

}


.modal-class {
  .steps-content {
    min-height: 260px;
    margin-top: 16px;
    padding-top: 44px;
    text-align: center;
    background-color: #9dbbd729;
    border: 1px #e9e9e9;
    border-radius: 2px;

    .ant-form-item-control-input {
      width: 62% !important;
    }

    .ant-row.ant-form-item {
      align-items: center;
      /* margin-left: 163px; */
      justify-content: center;
      margin-top: 17px;
    }

    .new-password {
      margin-top: -26px !important;
    }

    .ant-form-item-with-help .ant-form-item-explain {
      width: fit-content;
    }

    h1.lock-icon {
      position: absolute;
      top: 38%;
      left: 47%;
      font-size: xx-large;
    }

    .ant-form-item-control-input {
      width: 100% !important;
    }


  }

  .steps-action {
    margin-top: 24px;
  }

  // .ant-modal-content {
  //   width: 105vh;

  //   // right: 7vh;
  // }

  .ant-modal-body {
    // width: 105vh;
    padding: 17px;
  }

  .ant-modal-footer {
    display: none;
  }



  .done-message {

    align-items: center;
    justify-content: center;

  }

  h1.smile-icon {
    color: #2aeb2a;
    font-size: xx-large;
  }

  button.ant-btn.ant-btn-primary.validate-btn {
    margin-top: 2px;
  }

  h3.mail-text {
    margin-top: -37px;
    margin-left: -14px;

  }

  form#otp {
    margin-top: 35px;
  }

  .ant-modal {
    position: absolute !important;
    top: 24% !important;
    left: 23%;
  }

  p.password-reset {
    margin-top: 43px;
  }

  h4.create-new-password {
    margin-top: 17px;
  }

  .ant-form-item-explain-error {
    color: #ff4d4f;
    font-size: 11px;
  }

}

.social {
  width: 100%;
  position: relative;
  overflow: hidden;
  text-align: center;
  margin-bottom: 15px;
  
  span {
    display: inline-block;
    vertical-align: baseline;
    padding: 0 20px;
    
    &:before, &:after {
      content: '';
      display: block;
      width: 400px;
      position: absolute;
      top: 0.9em;
      border-top: 1px solid grey;
    }
    
    &:before {
      right: 55%;
    }
    
    &:after {
      left: 55%;
    }
  }
}