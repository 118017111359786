.login-test {
    .list-container {
      // height: calc(100% - 40px) !important;
      height: calc(100vh - 180px);
      overflow  : auto;
    }
    .attendee-body {
      margin-bottom: 4%;
      .avatar-Text{
        font-size: 20px;
      }
  
      .ant-card-body {
        padding: 14px;
      }
  
      h4 {
        margin: 0px;
        color : #3E8DAF;
      }
  
      h5 {
        margin: 0px;
      }
    }
    .kol-search {
      display        : flex;
      padding        : 0px 0%;
      justify-content: space-between;
      margin: 12px 0;
    }
    .center-all{
      display: flex !important;
      // flex-direction: column;
      width: 100% !important;
      align-items: center !important;
      justify-content: center !important;
    }
    .userData{
      background-color: #F9F9F9;
      padding: 10px 15px;
      border-radius: 10px;
      margin-top: 10px;
    }
    .fontWeight-500{
      font-weight: 500;
    }
    .email{
      color: #2973FE;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80px;
      display: inline-block;
    }
    .details{
       display: flex;
       justify-content:space-between;
       margin: 10px 0;
    }
    .ant-divider-horizontal {
        margin: 0px 0 !important;
    }
    .ant-collapse{
        border: none !important;
        background-color: none !important;
    }
    .ant-collapse>.ant-collapse-item >.ant-collapse-header {
        // border: none !important;
        background-color: white !important;
        padding: 6px 16px !important;
    }
    .ant-collapse .ant-collapse-content {
        border-top: none !important;
    }
    .ant-collapse .ant-collapse-content>.ant-collapse-content-box {
        padding: 0px 16px !important;
    }
    .ant-layout-footer {
      background: white !important;
      padding: 0px;
    }
    .ant-collapse-item.ant-collapse-item-active{
      background: white !important;
    }
    .ant-collapse-header-text{
      font-weight: 500;
    }
    .ant-checkbox-wrapper{
      padding: 5px 10px !important;
    }
    .filter-header{
        padding: 0px !important;
        height: 40px !important;
        background: white !important;
    }
    .filter-content{
        height: calc(100vh - 145px) !important;
        overflow-x: auto;
    }
  }
  