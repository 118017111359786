.page-not-found {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    & > div {
      position: relative;
      padding: 0 1rem;
      max-width: 640px;
      text-align: center;
      z-index: 1;
    }
  }