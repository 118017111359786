.class-33 {
  width: 33.33% !important;
}
.width-100 {
  width: 100%;
}
.ant-table-wrapper table {
  width: 100%;
  text-align: center;
  border-radius: 8px 8px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}

// .list-container .ant-table-wrapper .ant-table {
//   scrollbar-color: auto !important; 
// }
