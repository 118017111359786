@import './../../../../Shared/CSS/variables';
.ant-upload-select {
    display: grid !important;
    .ant-upload {
      width: 100%;
      display: grid !important;
    }
  }
  .form-item-mail{
    margin-top: 10px;
    margin-bottom: 10px;
  }
.edit-meet-button{
  background: $btn-primary-bg !important;
}