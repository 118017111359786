.icon {
  @import '../../Styles/colors.scss';
  display: inline-block;
  align-items: center;
  padding: 2px 4% 0 4%;
  margin: 1% 2%;
  vertical-align: middle!important;
  &.blank {
    width: 22px;
    display: inline-block;
  }
  .secondary {
    fill: #CCCCCC;
  }
}
