.agendaContainer {
  .agendaText {
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .btn-border {
    border: 1px solid #000000;
  }
  .agendaDate {
    font-size: 15px;
    font-weight: 500;
  }
  .noEvent {
    font-size: 14px;
    font-weight: 500;
  }
  .rbc-toolbar-label {
    margin: 5px 0;
    font-weight: 500;
  }
  .ant-select .ant-select-selection-item {
    font-weight: 500;
  }
}
