.icon-component-root {
  @import '../../Styles/colors.scss';
  height: calc(100vh - 90px);
  .page-header {
    padding: 20px 40px;
    font-size: 24px;
    border-bottom: 1px solid #DDD;
  }
  .page-contents {
    background-color: $background-secondary;
    height: calc(100% - 77px);
    padding: 20px 40px;
    .search-box {
      input {
        width: 60%;
      }
    }
    .icon-container {
      height: calc(100% - 50px);
      overflow: auto;
      display: inline-flex;
      flex-wrap: wrap;
      margin-top: 10px;
      .icon-card {
        width: 150px;
        height: 100px;
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: 5px;
        padding: 10px 15px;
        background-color: $background-primary;
        margin: 3px;
        // color: $font-color-black;
        svg {
          // fill: $font-color-black;
          height: 24px;
          width: 24px;
        }
        &:hover {
          cursor: pointer;
          background-color: #faf4ff;
          box-shadow: 1px 1px 15px #00000020;
        }
      }
    }
  }
}
