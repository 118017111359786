@import '../../../Shared/CSS/variables';
.container {
    padding: 0%;

    .ant-card-body {
        padding: 10px;
    }
    .tabs {
        margin : 0px;
        padding: 2%;
    }

    .agendaCard {
        // height: calc(100vh - 128px) !important;
        // overflow: scroll;
        // margin-top: 10px;
        margin: 0.7%;
        // .add-Meeting-button{
        //   display: flex;
        //   justify-content: center;
        // }
        .ant-card-body {
            padding: 14px;
        }

        .ant-tabs-tab {
            padding: 6px;
        }
    }
}
.element {
  width: 820px;
  height: 1180px;
}

.rbc-btn-group{
  margin: 5px 0;
}
.rbc-toolbar-label{
  margin: 5px 0;
}

/* Responsive styles for smaller viewports */
@media screen and (max-width: 768px) {
  .element {
      width: 100%; /* Adjust width to fit the viewport */
      height: auto; /* Maintain aspect ratio */
  }
}
.border-card{
  border: 1px solid #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgb(204, 204, 204) 1px 1px 10px 1px;
}

.attendees {

    h4 {
        margin-bottom   : 0px;
        // border-bottom: 1px solid #dee2e6;
        padding         : 10px;
    }

    .attendees_link {
        font-size  : 16px;
        padding    : 7px 10px;
        font-weight: 400;
        text-align : left;
        margin     : 0px !important;
        display    : flex;
    }
}

.text-center {
    text-align: center;
}

.flex {
    display: flex;
}

.justify-content-center {
    justify-content: space-between;
}

.text-white {
    color: #fff !important;
}


.detailsList {
    width      : 100%;
    // height: calc(100% - 210px);
    // overflow: auto;
    // margin-top : 15px;

    .agenda-header {
        // margin-top: 10px;

        // h4 {
        //     margin          : 0px !important;
        //     text-align      : center;
        //     color           : #fff;
        //     padding         : 1px 0px;
        //     background-color: #356A7C;
        // }

        // .agendaTime {
        //     display   : flex;
        //     margin-top: 5px;
        // }

        h5 {
            text-align      : center;
            // padding      : 5px 10px;
            // font-weight  : 400;
            // text-align   : left;
            margin-bottom: 0px;
        }

        

        .agendaDetails {
            margin-top     : 5px;
            display        : flex;
            justify-content: center;

            .ant-card-body {
                padding: 14px;
            }
        }

        .con-agenda {
            border-left: 2px solid #DBDDDF;

            h5 {
                margin: 0px !important;
            }
        }
    }

    .schedule {
        background-color: #F2F2F2;
        padding         : 2%;

        h3 {
            background-color: #FFEF88;
            padding         : 1%;
            margin          : 0px;
        }

        h5 {
            text-align      : left;
            margin-top      : 5px;
            background-color: #D9D9D9;
            padding         : 1%;
            cursor          : pointer;
        }
    }
    .margin-class{
      margin-top: 0px ;
    }
    .width-100{
      width: 100% !important;
    }

    .class-33{
      width: 33.33% !important;
    }


}

.conference-agenda-height {
    height: calc(100vh - 325px);
    overflow: auto;
}
.schedule-agenda-height{
    height  : calc(100vh - 210px);
    overflow: auto;
}

.attendee-search {
    height: calc(100vh - 205px);
    overflow: hidden;

    .ant-card-body {
        padding: 0px;
      }
   
    .conference-height{
        height: calc(100vh - 237px);
        overflow: auto;
        padding: 0.5%;
        margin-top: 15px;
    }

    .justify-content{
      justify-content: space-between;
    }

    .conference-card {
      margin-bottom: 8%;
      .ant-card-body {
        padding: 10px;
      }

      .parent {
        position: relative;
        top: 0;
        left: 0;
      }
      .image1 {
        position: relative;
        top: 0;
        left: 0;
        border: 1px red solid;
      }
      .image2 {
        position: absolute;
        top: 30px;
        left: 30px;
        border: 1px green solid;
      }

      h3 {
        margin: 0px;
        color : #fff;
      }

      h5 {
        margin-top: 0.2em;
        color     : #fff;
      }

      .conference-text {
        display        : flex;
        justify-content: space-between;
      }
      .conference-team{
        color: #035876;
      }

      .hrs {
        border       : 1px solid #ddd;
        padding      : 5px 10px;
        border-radius: 50%;
        color: #035876;
      }

      .conference-text-color {
        color: #fff;
      }
      .conference-time{
        margin-top: 5px;
      }
    }

    .attendee-body{
      margin-bottom: 8%;
      .ant-card-body {
        padding: 14px;
      }
      h4{
        margin: 0px;
        color: #3E8DAF;
      }
      h5{
        margin: 0px;
      }
    }

    .con-row {
      width: 100%;

      .tile-profile {
        background-color: #8DB0AB;
        color           : #fff;
        margin          : 2%;
        padding         : 25px 0px;
        cursor          : pointer;
        border-radius   : 2px;
        box-shadow      : 1px 1px 11px 6px #cccccc;

        &:hover {
          border-radius: 2px;
          box-shadow   : 1px 1px 10px 6px #9f9f9f;
        }
      }

      h3 {
        color        : #fff;
        margin-bottom: 5px !important;
      }

      h5 {
        color : #fff;
        margin: 5px 0px;
      }

      .con-div {
        justify-content: center;
        display        : grid;

        h5 {
          color : #fff;
          margin: 0px 0px !important;
        }
      }
    }
  }

// .search-kol {

//    margin: 15px 0px !important;


//   }
// .meeting-root {
//     // @import "../../../../Shared/Styles/colors.scss";
//     // padding         : 1.5rem 0;
//     // background-color: $background-secondary;
//     // height          : calc(100% - 90px);


//     .list-container {
//         padding   : 0 1.5rem;
//         // height: calc(100% - 50px);
//         height    : calc(100% - 40px);
//         margin-top: 10px;
//         overflow  : auto;

//         .tile {
//             .tile-profile {
//                 cursor          : pointer;
//                 margin          : 0% 1% ;
//                 background-color: #fff;
//                 border-radius   : 4px;
//                 padding         : 10px;

//                 h3{
//                     text-align: center;
//                 }
//                 .text{
//                     text-align: center;
//                 }

//                 .agenda-header{
//                     border-radius     : 0.375rem;
//                     padding-top: 1%;

//                     a{
//                         font-size: 16px;
//                         padding: 7px 10px;
//                         font-weight: 400;
//                         text-align: left;
//                         margin: 0px !important;
//                         display: flex;
//                     }

//                     h4{
//                         margin-bottom: 0px;
//                         border-bottom: 1px solid #dee2e6;
//                         // text-align: center;
//                         padding: 10px;
//                     }

//                 .detailsList{
//                     display: flex;
//                     justify-content: space-between;


//                     h5{
//                         padding: 7px 10px;
//                         font-weight: 400;
//                         text-align: left;
//                         margin: 0px !important;
//                     }

//                 }


//                 }
//             }
//         }
//     }
// }

.calender-big{
    
  .rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
    border: 0 !important;
    // max-height: 20px;
    // min-height: 20px;
    // border:0 !important;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute;
    font-size: 12px;
    display: block !important;
  }

  .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    background-color:  $btn-primary-bg !important;
    border-color: #adadad;
    color: white !important;
  }
  .rbc-toolbar button:focus {
    color: white !important;
    background-color: $btn-primary-bg !important;
    border-color: #adadad;
  }

  .rbc-toolbar button.rbc-active {
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    background-color: $btn-primary-bg !important;
    border-color: #adadad;
    color: white !important;
  }
  .rbc-today {
    background-color: #f6f6f6 !important;
  }
  .rbc-event, .rbc-day-slot .rbc-background-event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 3px 5px;
    // background-color: #FFE539; 
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left;
    background-color:  #E8EBFA !important;
    border-width: 1px 1px 1px 4px !important;
    border-radius: 4px !important;
    // background-image: linear-gradient(to right, #3D8CAE, #C3D8E0);
  }
  .rbc-event.rbc-event-allday.rbc-event-continues-prior.rbc-event-continues-after{
    background-color:  #E8EBFA !important;
    border-width: 1px 1px 1px 4px !important;
    border-radius: 4px !important;
  }
  .rbc-calendar *, .rbc-calendar *:before, .rbc-calendar *:after {
    cursor: pointer !important;
    border-radius: 4px !important;
    font-size: 15px;
    font-weight: 500;
  }
  .rbc-header {
    min-height: 2rem;
    border-radius: 0 !important;
    // padding: 12px 15px;
    text-align: start;
  }
  .rbc-month-view{
    overflow: auto;
  }
  .rbc-month-header{
    background: white;
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
  }
  .rbc-row-bg{
    background-color:#FAFAFA;
  }
//   .rbc-month-view .rbc-row-content .rbc-row {
//     height: 120px; /* Adjust the height as needed */
//   }
  .rbc-month-view .rbc-date-cell {
    // border-right: 0.2px solid #d9d9d9;
    // min-height: 100px;
}

.rbc-month-view .rbc-month-row{
min-height: 110px !important;
}
.rbc-row-segment{
  margin-bottom:5px;
  padding-right:10px;
}
.rbc-event-content{
  font-size: 13px;
}
  .rbc-allday-cell{
    // margin-top: 26px;
  }
  .rbc-button-link{
    display: block;
    // padding-left: 10px;
    width: 100%;
    text-align: left;
  }
  .rbc-month-view .rbc-date-cell {
    padding: 5px 10px !important;
    box-sizing: border-box; /* Ensure padding does not affect overall block size */
  }
  .rbc-month-view .rbc-header {
    // padding-left: 10px;
    // padding-top: 10px;
  }
  .rbc-time-view .rbc-row {
    box-sizing: border-box;
    min-height: 4rem;
  }
  .rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px; 
    font-weight: 500;
}
/* Remove background color for previous and next month days */
.rbc-day-bg.rbc-off-range-bg {
  background-color: white !important;
}

/* Change color of previous and next month date numbers */
.rbc-date-cell.rbc-off-range {
  color: #B8BCBF !important;
}

/* Change color of current month date numbers */
.rbc-date-cell:not(.rbc-off-range) {
  color: black !important;
}
  .rbc-label{
    bottom: -8px;
    position: relative;
  }
  .rbc-event-content{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .rbc-toolbar {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: baseline;
    margin-bottom: 10px;
    font-size: 12px;
    align-content: space-between;
  }
  .rbc-toolbar .rbc-toolbar-label {
    font-weight: bold;
  }
}

.specialDay {
  background-color: #fec;
}
.btn-border{
  border: 1px solid #000000;
}
.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  // max-height: 100px !important;
  overflow: scroll !important;
  position: relative;
}
.rbc-event-content{
  font-size: 12px;
}
.rbc-day-slot .rbc-event-label{
  display:none
}
.rbc-day-slot .rbc-event-content{
  align-items: center;
  display: flex;
}
// .rbc-toolbar-label.ant-select.ant-select-selector{
//   border: none;
//   font-size: 17px;
// }
// .rbc-overlay {
//   left: inherit !important;
// }

// .rbc-event, .rbc-day-slot .rbc-background-event {
//   max-width: 500px !important;
// }
.no-border .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  font-size: 14px;
}
.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0;
  flex-basis: 0px;
  overflow: visible !important;
  height: 100% !important;
}

// .rbc-event .rbc-day-slot .rbc-background-event{
//   height: 2em !important;
// }

// .rbc-row-content .rbc-row:nth-child(n + 3) { display: none; } 