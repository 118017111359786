.adminHomeContainer {
    height: calc(100vh - 90px);
    .admin-body-container {
        padding: 18px 23px 5px 23px;
    }

    section.ant-layout.ant-layout-has-sider.admin-body-container {
        height: 100%;
    }

    .ant-layout-header {
        height: 42px;
        padding: 0px 24px;
        align-items: center;
        line-height: 42;
        color: #3983d7;
        background: white;
        border-bottom: 1px solid #d7d7d7c7 !important;
        display: flex;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: #e6f0fa!important;
    }
    .container-right-card-admin{
        border-radius: 10px;
        height: 100%!important;
    }
    .ant-breadcrumb a:hover, .ant-breadcrumb li:last-child, .ant-breadcrumb li:last-child a {
         color: rgba(0,0,0,.45);
    }
}

.admin-body-card {
    .ant-card-body{
        padding: 10px 24px 24px 24px !important;
    }
}

@media  screen and (max-width:767px) {
    .adminHomeContainer {
        .menuContainer{
            display: none;
        }
        .admin-body-container {
            padding: 18px 5px;
        }
       .container-right-card-admin{
            border-radius: 10px;
            height: 100%!important;
        }
    }
}