.login-test-KOL {
  .list-container {
    // height: calc(100% - 40px) !important;
    height: calc(100vh - 180px);
    overflow  : auto;
  }
  .attendee-body {
    margin-bottom: 4%;
    .avatar-Text{
      font-size: 20px;
    }

    .ant-card-body {
      padding: 8px;
    }

    h4 {
      margin: 0px;
      color : #3E8DAF;
    }

    h5 {
      margin: 0px;
    }
  }
  .kol-search {
    display        : flex;
    padding        : 0px 0%;
    justify-content: space-between;
    margin-top     : 15px;
  }
  .center-all{
    display: flex !important;
    // flex-direction: column;
    width: 100% !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .fontWeight-500{
    font-weight: 500;
  }
  .email{
    color: #235D9F;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    display: inline-block;
  }
  .details{
     display: flex;
     justify-content:space-between;
     margin: 10px 0;
  }
  .ant-divider-horizontal {
      margin: 0px 0 !important;
  }
  .ant-collapse{
      border: none !important;
      background-color: none !important;
  }
  .ant-collapse>.ant-collapse-item >.ant-collapse-header {
      // border: none !important;
      background-color: white !important;
      padding: 10px 20px !important;
  }
  .ant-collapse .ant-collapse-content {
      border-top: none !important;
  }
  .ant-collapse .ant-collapse-content>.ant-collapse-content-box {
      padding: 0px 16px !important;
  }
  .ant-layout-footer {
    background: white !important;
    padding: 0px;
  }
  .ant-collapse-item.ant-collapse-item-active{
    background: white !important;
  }
  .ant-collapse-header-text{
    font-weight: 500;
  }
  .ant-checkbox-wrapper{
    padding: 5px 10px !important;
  }
  .filter-header{
      padding: 0px !important;
      height: 40px !important;
      background: white !important;
  }
  .filter-content{
      height: calc(100vh - 141px) !important;
      overflow-x: auto;
  }
  .attributes{
    display: flex;
    font-size: 14px;
    padding: 15px 20px;
    color: #235D9F;
    cursor:pointer;
    font-weight: 400;
  }
  .ant-radio-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Increase width */
    height: 40px; /* Decrease height */
    background-color: white;
    border: 1px solid #d9d9d9;
    padding: 0; /* Remove padding if any */
  }
  
  .clearFilters{
    font-size: 12px;
    color: #235D9F;
    cursor: pointer;

  }
  .clearFilters:hover{
    text-decoration: underline;
  }
  .ant-radio-button-wrapper:hover {
    border-color: #1890ff;
  }
  
  .ant-radio-button-wrapper-checked {
    background-color: black;
    border-color: black;
    color: white;
  }
  
  .ant-radio-button-wrapper-checked .fontCircleInfo {
    fill: white; /* Ensure SVG icon color changes to white */
  }
  .label{
    color: #6D7073;
    font-size: 12px;
    font-weight: 400;
  }
  .value{
    font-size: 12px;
  }
  .userData{
    background-color: #F9F9F9;
    padding: 10px 15px;
    border-radius: 10px;
    margin-top: 10px;
  }
  .count{
    font-weight: 500;
    font-size: 14px;
  }
  .countText{
    font-weight: 400;
    font-size: 14px;
  }
  .ant-table-wrapper .ant-table-pagination.ant-pagination{
    margin: 8px 0 8px 0 !important;
  }
  .countWrapper{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-btn-primary.ant-input-search-button{
    background: #ffffff !important;
    border-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.88) !important;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02) !important;
  }
  .ant-table-cell {
    padding: 5px 5px 5px 15px !important;
    font-size: 13px;
    font-weight: 500;
  }
  .ant-table-thead > tr > th {
    background-color: #EBEBEB; 
    padding:15px 7px 15px 15px !important; 
    margin: 4px; 
  }
  // .ant-table-thead > tr > th,
  // .ant-table-tbody > tr > td {
  //   padding: 16px !important;
  // }
}