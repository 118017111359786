.home-breadcrumb {
  // color        : #E0B41C;
  color: black;
  font-weight: 600;
  transition   : color 0.2s;
  padding      : 0 4px;
  border-radius: 0px;
  height       : 22px;
  font-weight  : 500;
  display      : inline-block;
  margin-inline: -4px;
  cursor: pointer;

  .arrow-display{
    display: inline;
    padding-right: 5px;
  }

  // div{
  //     &:hover {
  //         background-color: #E0B41C;
  //         color           : #fff;
  //     }
  // }
  // .subLink{
  //   &:hover {
  //     background-color: #E0B41C;
  //     color           : #fff;
  // }
  // }
}

.ant-bread {
  // color        : #E0B41C;
  transition   : color 0.2s;
  padding      : 0 4px;
  border-radius: 4px;
  height       : 22px;
  font-weight  : 500;
  display      : inline-block;
  margin-inline: -4px;
  cursor: pointer;
  opacity: 0.9;

  span{
      &:hover {
          // background-color: #E0B41C;
          // color           : #fff;
      }
  }
 
}



// .breadcrumb-root {
//   @import "../../Styles/colors.scss";
//   width: 100%;
//   // height: 67.5px;
//   padding: 0 0;
//   .left {
//     width: 246px;
//     .title {
//       font-size: 18px;
//       padding: 20px;
//       color: $font-color-black;
//     }
//   }
//   .right {
//     // width: calc(100% - 246px);
//     width: 100%;
//     padding: 0.8rem 3.2rem 0.8rem 0rem;
//     .current-page {
//       font-size: 18px;
//       font-weight: bold;
//     }
//     .action-buttons {
//       position: absolute;
//       right: 50px;
//       margin-top: -32px;
//       &> .btn {
//         margin: 2px 3px;
//       }
//     }
//     .active {
//       color: $blue-1;
//     }
//   }
//   @media (max-width: 767px) {
//     font-size: 85%;
//     padding-left: 15px;
//     .left {
//       display: none;
//     }
//     .right {
//       width: 100%;
//       .current-page {
//         font-size: 16px;
//       }
//       .action-buttons {
//         right: 10px;
//       }
//     }
//     .btn {
//       font-size: 100%;
//       padding: 2px 10px;
//       display: block;
//     }
//   }
// }
