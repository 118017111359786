
@font-face {
  font-family: "Inter-Regular";
  /* Most used */
  src: url("../Assets/fonts/Inter/Inter-Regular.ttf");
}
@font-face {
  font-family: "Inter-Medium";
  /* Most used */
  src: url("../Assets/fonts/Inter/Inter-Medium.ttf");
}
@font-face {
  font-family: "Inter-Light";
  /* Most used */
  src: url("../Assets/fonts/Inter/Inter-Light.ttf");
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: 'Inter-Regular', sans-serif!important;
}

.App {
  height: 100%;
  background-color: #f6f6f6;
  overflow: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #00287a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.ant-layout-header {
  padding: 0 20px!important;
  height: 42px!important;
  color: rgba(29, 29, 29, 0.85)!important;
  line-height: 42px!important;
  background: #ffffff!important;
  font-weight: 500!important;
  font-family: 'Inter-Medium'!important;

}
// .header-content{
//   position: sticky;
//   top: 0;
//   z-index:99;
// }

.App-link {
  color: #61dafb;
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}


/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(150, 150, 150);
  border-radius: 20px;
  
}

/* Handle on hover */



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card-opacity {
  opacity: -1;
}

.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: -11px !important;
}

.ant-layout {
  background: rgba(255, 255, 255, 0) !important;
}


.ant-menu-inline .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #F6F6F6 !important;
  transform: scaleY(.0001);
  opacity: 0;
  transition: transform .15s cubic-bezier(.215,.61,.355,1),opacity .15s cubic-bezier(.215,.61,.355,1);
  content: "";
}

.ant-switch-checked{
  background-color: green !important;
}
.ant-empty-description{
  color: rgb(149, 149, 149);
}
.ant-menu-horizontal {
  line-height: 42px!important;
  border: 0!important;
  border-bottom: 1px solid #d7d7d7!important;
  box-shadow: none!important;
}
.ant-avatar-string{
  font-size: 11px;
  opacity: 0.8;
  letter-spacing: 0.5px;
  cursor: pointer;
}
table {
  th {
    font-size: 12px;
    font-family: 'Inter-Medium';
  }
}
.ant-select-selector {
  cursor: pointer !important;
}


@media screen and (max-width: 767px){
  .ant-layout-header {
    padding: 0 10px!important;
  }
}

@media screen and (max-width: 1280px){
  body{
    zoom:98%;
  }
}

.text-mandatory {
  color: red;
  opacity: .8;
  padding-left: 2px;
}

.ant-table-wrapper .ant-table {
  scrollbar-color: auto !important; 
}

.center-all{
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
}