html, body{
  height: 100% !important;
  width: 100%;
}
body, html {
  width: 100%;
}


div#root {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Inter-Regular Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.text-red {
  color: #dc3545;
}

.text-ellipsis {
  text-overflow: ellipsis;
  /* width: 100%; */
  white-space: nowrap;
  overflow: hidden;
}
.toast-container {
  z-index: 4;
}
.toast-container .alert-heading {
  font-size: 1.2rem;
}
