.header-main {
  @import "../../../Shared/Styles/colors.scss";
  background-color: #FFE539;
  text-align: center;
  color: $font-color-black;
  height: 45px;
  padding: 6px 40px 6px 0px;
  // border-bottom: 1px solid $header-border-color;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 100;
  .info {
    display: flex;
    justify-content: space-between;
  }
  .company-text {
    padding-top: 2px;
    div {
      font-family: Inter-Medium;
    }
  }
  .profile-pic {
    color: $font-color-black;
    padding: 2px 5px 5px 5px;
    margin-right: 3px;
  }
  .bell-notification {
    color: $font-color-black;
    padding: 2px 5px 5px 5px;
    margin-left: 4px;
    margin-bottom: 4px;
  }
  .notification {

    /* circle shape, size and position */
    position: absolute;
    right: -0.5em;
    top: -0.5em;
    min-width: 1.6em; /* or width, explained below. */
    height: 1.6em;
    border-radius: 0.8em; /* or 50%, explained below. */
    border: 0.05em solid white;
    background-color: black;
  
    /* number size and position */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6em;
    color: white;
  }
  .mailbox {
    position: relative;
    font-size: 16px;
    // line-height: 2.5em;
    margin: 0.3em;
  }
  .right-menu .ant-btn{
    margin-top: 2px;
    padding: 0;
  }
  :where(.css-dev-only-do-not-override-usln0u).ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    color: rgba(0, 0, 0, 0.88);
    background: none !important;
  }
  .text {
    // color: white;
    line-height: 1.2;
    .role {
      font-size: 80%;
      text-align: left;
    }
  }
}
.name {
  margin-top: 5px;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
 .header-main{
  padding: 6px 15px 6px 15px;
 }
}
