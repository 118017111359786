$border-color: #eeeeef;

$blue-1: #106cd4;
$blue-2: #23A9F4;

$background-primary: white;
// $background-secondary: #dfe3e4;
$background-secondary: #f2f3f3;
$hover-dark: #00000015;

/** FONT */
$font-color-white: white;
$font-color-black: #32363a;
$font-color-black-80: #32363a80;
$font-placeholder-color: #ffffffcc;
$text-orange: #ff9100;

/** Button */
$btn-primary: $blue-1;
$btn-primary-border: $blue-1;
$btn-primary-font: #fff;
$btn-primary-hover: rgba($btn-primary, 0.8);

$btn-secondary: #ebf5fe;
$btn-secondary-border: $blue-2;
$btn-secondary-font: $blue-2;
$btn-secondary-hover: rgba($btn-secondary, 0.8);

$btn-link: transparent;
$btn-link-border: transparent;
$btn-link-font: $blue-1;

$btn-icon: transparent;
$btn-icon-border: transparent;
$btn-icon-font: $blue-2;


/** HEADER */
$header-bg: #082c7c;
$header-border-color: #fafafa;
$header-search-bg: #ffffff16;
$header-active-menu: $blue-1;
$top-menu-bg: #ffffff;

/** Settings Side Menu */
$menu-hover: #e9f4ffa0;
$menu-icon: $blue-2;
$sub-menu-active-color: $blue-1;

/** Employee List Nav **/
$emp-nav-border: #ccc;
$emp-nav-active: #e9f4ff;
$emp-nav-bg: white;
$sub-menu-active-bg: #E9F4FF;
$sub-menu-active-color: $blue-1;
$menu-border-color: #EEEEEF;

/** Form */
$form-border-color: #89919a;
$form-shadow-color: #2684ff;
$form-error-text: #DC3545;
$form-placeholder-color: #32363a;
$form-label-color: #6a6d70;
$form-text-color: #32363a;

/** Status */
$status-placeholder: #aaaaaa;